import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";

function LoginAuth(props) {

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const status = query.get("status");
  const token = query.get("token");
  const id = query.get("id");
  const next = query.get("next");

  if (status == "OK") {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('id', id);
    const redirectUrl = next ?? "/form";
    window.location.replace(redirectUrl);
  } else
    return <div>Ha ocurrido un error iniciando sesión</div>;
  return <div></div>;
}

LoginAuth.propTypes = {};

export default LoginAuth;